<template>
  <footer>
    <section class="container social">
      <div class="left">
        <MenuSocial :showLogin="false" />
      </div>

      <div class="middle">
        <inline-svg
          v-on:click="scrollToTop"
          class="logo"
          :src="Logo"
          title="Logo SM"
        />
      </div>

      <div class="right">
        <p>{{ year }}. {{ $t("footer.reserved") }}</p>
      </div>
    </section>

    <hr />

    <section class="container map">
      <Menu :links="links" />

      <div class="with-love">
        <p>{{ $t("footer.with_love.first") }}</p>
        <inline-svg class="icon-love" :src="IconHeart" title="Love" />
        <p>{{ $t("footer.with_love.second") }}</p>
        <a href="https://github.com/felipeaufe" target="_blank">{{
          $t("footer.with_love.by")
        }}</a>
      </div>
    </section>
  </footer>
</template>

<script>
import Logo from "@/assets/img/logo/sm-logo.svg";
import Menu from "@/components/Menu.vue";
import MenuSocial from "@/components/MenuSocial.vue";
import IconHeart from "@/assets/img/icons/heart.svg";

export default {
  name: "FooterElement",
  components: {
    Menu,
    MenuSocial
  },
  data: () => ({
    Logo,
    IconHeart,
    year: new Date().getFullYear(),
    links: []
  }),
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.links = [
      {
        link: "/tickets",
        text: this.$t("navbar.links.tickets")
      },
      // {
      //   link: "/dance-pack",
      //   text: this.$t("navbar.links.dance_pac")
      // },
      // {
      //   link: "/classes",
      //   text: this.$t("navbar.links.classes")
      // },
      {
        link: "/teachers",
        text: this.$t("navbar.links.teachers")
      },
      // {
      //   link: "/djs",
      //   text: this.$t("navbar.links.djs")
      // },
      // {
      //   link: "/team",
      //   text: this.$t("navbar.links.team")
      // },
      // {
      //   link: "/events",
      //   text: this.$t("navbar.links.events")
      // },
      // {
      //   link: "/about",
      //   text: this.$t("navbar.links.about")
      // },
      {
        link: "/team",
        text: this.$t("navbar.links.team")
      },
      // {
      //   link: "/events",
      //   text: this.$t("navbar.links.events")
      // },
      {
        link: "/about",
        text: this.$t("navbar.links.about")
      },
      {
        link: "/terms-of-use",
        text: this.$t("navbar.links.terms")
      },
      {
        link: "/privacy",
        text: this.$t("navbar.links.privacy")
      }
    ];
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_mixin";

footer {
  background-color: var(--color-sm-primary);
  height: var(--sizing-180);

  @include device(mobile) {
    height: auto;
  }

  .logo {
    cursor: pointer;
  }

  ul {
    display: flex;
    height: 100%;

    li {
      color: var(--color-white);

      a {
        height: 100%;
        display: flex;
        padding: 0 var(--spacing-10);
        align-items: center;
        @include transition(background-color 0.1s ease-in-out);

        &:hover {
          background-color: var(--color-white-opacity-20);
        }

        svg {
          fill: var(--color-white) !important;
          height: var(--sizing-20);
          width: var(--sizing-20);
        }
      }
    }
  }

  .icon-love {
    fill: var(--color-white);
  }

  p {
    color: var(--color-white);
  }

  hr {
    border-color: var(--color-sm-primary-border);
    margin: 0;
  }

  section.social {
    display: flex;
    padding: var(--spacing-15) 0;

    @include device(mobile) {
      flex-direction: column;
      .left,
      .middle,
      .right {
        width: 100%;
        justify-content: center !important;
      }
      .left {
        order: 2;
        margin: var(--spacing-10) 0;
      }
      .middle {
        order: 1;
      }
      .right {
        order: 3;
      }
    }

    > div {
      width: calc(100% / 3);
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .middle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-size: var(--font-size-14);
      }
    }
  }

  section.map {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--spacing-15) 0;

    ul {
      margin-top: calc(var(--spacing-15) * -1);

      @include device(mobile) {
        flex-wrap: wrap;
        justify-content: center;
      }

      li a {
        padding: var(--spacing-15) var(--spacing-15);
      }
    }

    .with-love {
      display: inline-flex;
      margin-top: var(--spacing-5);

      p,
      a {
        color: var(--color-sm-text-dark);
        font-size: var(--font-size-14);
      }

      a {
        margin-left: var(--spacing-5);
        text-decoration: revert;
      }

      .icon-love {
        margin: 0 var(--spacing-5);
        fill: var(--color-sm-text-dark);
        width: var(--sizing-20);
        height: var(--sizing-20);
      }
    }
  }
}
</style>
