import home from "../views/home/routes";
import dance from "../views/dancePack/routes";
// import contact from "../views/contact/routes";
import certificate from "../views/certificate/routes";
import checkout from "../views/checkout/router";
import tickets from "../views/tickets/routes";
import login from "../views/login/routes";
import forgot from "../views/forgot/routes";
import reset from "../views/reset/routes";
import profile from "../views/profile/routes";
import create from "../views/create/routes";
import expired from "../views/expired/routes";
import teachers from "../views/teachers/routes";
import djs from "../views/djs/routes";
import team from "../views/team/routes";
import terms from "../views/terms/routes";
import privacy from "../views/privacy/routes";
import classes from "../views/classes/routes";
import notFound from "../components/NotFound.vue";

const defaultRoute = [
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: notFound
  }
];

export default [
  ...defaultRoute,
  ...home,
  ...dance,
  // ...contact,
  ...certificate,
  ...checkout,
  ...tickets,
  ...login,
  ...forgot,
  ...reset,
  ...profile,
  ...teachers,
  ...djs,
  ...team,
  ...create,
  ...expired,
  ...terms,
  ...classes,
  ...privacy,
  {
    path: "/about",
    name: "about",
    component: () => import("../views/home/index.vue")
  }
];
